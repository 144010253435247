.formButton {
    background-color: #a28089 !important;
    color : white!important;

}

.formButton:focus,
.formButton:active {
    background-color: #a28089 !important;
    outline: none;
    box-shadow: none !important;
}

.generatedPoemButtonsDiv {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 30px;
}

#prenomBox {
    width: 300px;
    height: 40px;
    font-size: 22px;
    padding: 25px;
    border: 2px solid #cecece;
    border-radius: 12px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    margin: auto;
    margin-left: 0px;
}

.occasionChoix {
    display: flex;
    align-items: center;
}

.occasionChoix img {
    width: 30px;
    height: 30px;
    margin: 0;
    margin-left: 10px;
}

#prenomImg {
    width: 32px;
    height: 32px;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 20px;
}

#poem {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: max-content;
    min-height: 516px;
    min-height: 380px;
}

#poem .card-header {

    font-size: 25px;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    #prenomBox {
        width: 100%;
        margin-left: 0;
        margin-bottom: 30px;
        margin-top: 30px;

    
    }

    #prenomImg{
        display: none;
    }

   .d-flex{
    flex-direction: row;
   }

    .occasionChoix img {
        width: 25px;
        height: 25px;
    }

    #prenomImg {
        width: 28px;
        height: 28px;
    }

    #poem {
        min-height: auto;
    }


}